import React, { useState, useEffect } from 'react';
import { Gallery, Violator } from '@hpstellar/core';

import withConfigurations from '../../../../product/components/product-configurations';
import {
    getEnvironmentalCertificationBadges,
    getConfigurations,
    getSelectedVariant,
    getColorId,
} from '../../../../product/get-product-props';
import withPageComponents from '../../../../page/components/with-page-components';
import LCPPrefetch, { getPrefetchImageArray } from '../../../../shared/components/lcp-prefetch';
import ImageModal from './image-modal';

import './pdp-gallery.less';

const mapImages = (images, selectedVariant) => {
    let { fullImages, zoomImages: xlImages } = images;
    let defaultImages = fullImages || [];
    let zoomImages = xlImages || [];
    if (selectedVariant) {
        const { value } = selectedVariant;
        //TODO: If more than one color and there is no match, might need to default to the first color seen
        defaultImages = (fullImages || []).filter(({ color }) => !color || getColorId(color) === getColorId(value));
        zoomImages = (xlImages || []).filter(({ color }) => !color || getColorId(color) === getColorId(value));
    }

    return {
        defaultImages,
        zoomImages,
    };
};

/**
 * Replaces video thumbnail with first product images.
 * Returns only the first image for SSR, since lazy load doesn't work well with SSR
 * @param {*} images
 * @param {*} mounted
 * @returns
 */
const setVideoDefaultImages = (images, mounted, productInitial) => {
    if (!images || images.length === 0) return [];

    const alt = `${productInitial.pm_category || productInitial.pm_subcategory}, ${productInitial.name}`;
    const setImageAlt = (img, count) => {
        return { ...img, alt: `Series ${count} of ${images.length} ${alt}` };
    };

    const firstImage = { ...images[0], alt };
    if (!mounted) {
        return [firstImage, ...images.slice(1).map((i, idx) => ({ url: '' }))];
    }
    return images.map((img, index) => {
        if (img.type === 'video') {
            return { ...img, url: firstImage.url, alt };
        }
        return setImageAlt(img, index + 1);
    });
};

/**
 * Adds environmental certification badges to to any existing partner logos
 * @param {*} product
 * @param {*} pdpPartnerLogos
 * @returns
 */
const getBadges = (product, pdpPartnerLogos) => {
    const envBadges = getEnvironmentalCertificationBadges(product);
    if (envBadges.length > 0) {
        return [...(pdpPartnerLogos || []), ...envBadges];
    }
    return pdpPartnerLogos || [];
};

const pdpGallery = ({
    pdpImages,
    ctoConfig,
    pdpPartnerLogos,
    productInitial,
    device,
    ctaProps,
    enableZoomGallery = true,
}) => {
    if (pdpImages && productInitial) {
        const { colorCTOs, sku } = productInitial;
        const { openGalleryGtmActions, getGtmActions } = pdpImages || {};
        const [imageIndex, setImageIndex] = useState(-1);
        const [mounted, setMounted] = useState(false);
        //don't render gallery till interaction is made
        const [loadModal, setLoadModal] = useState(false);
        const configurations = getConfigurations(colorCTOs, ctoConfig.color);
        const selectedVariant = getSelectedVariant(configurations, ctoConfig.color);
        const mappedImages = mapImages(pdpImages, selectedVariant);
        const images = setVideoDefaultImages(mappedImages?.defaultImages, mounted, productInitial);
        const badges = getBadges(productInitial, pdpPartnerLogos);
        const prefetchImages = getPrefetchImageArray(`${sku}-${images.length}`, images.slice(0, 1), image => {
            return { href: image?.url };
        });

        useEffect(() => {
            if (!mounted) {
                setMounted(!mounted);
            }
        });
        if (!images || images.length < 1) {
            return null;
        }

        //TODO: Temp solution would be better to have Gallery component take lazyLoad as a prop
        return (
            <>
                <LCPPrefetch images={prefetchImages} />
                {productInitial.usageLabel && device === 'mobile' && (
                    <Violator value={productInitial.usageLabel} className={'right mobile-usage'} small />
                )}
                <Gallery
                    /** set key to cto color so switch color options with different amount of images doesn't break*/
                    key={ctoConfig.color || 'default'}
                    onExpand={
                        enableZoomGallery
                            ? index => {
                                  try {
                                      if (!loadModal) {
                                          setLoadModal(true);
                                      }
                                      setImageIndex(index);
                                  } catch (e) {}
                              }
                            : null
                    }
                    className={`pdp-gallery${!mounted ? ' ssr' : ''}`}
                    images={images}
                    lazyLoad={mounted ? 'ondemand' : null}
                    gtmActions={openGalleryGtmActions}
                    badges={badges}
                    badgesPosition="bottom-center"
                    controls={'thumbnails'}
                    thumbnailsOrientation="vertical"
                    badgesCustomSize
                />
                {loadModal && (
                    <ImageModal
                        imageIndex={imageIndex}
                        setImageIndex={setImageIndex}
                        images={mappedImages}
                        ctoConfig={ctoConfig}
                        getGtmActions={getGtmActions}
                        ctaProps={ctaProps}
                    />
                )}
            </>
        );
    }
    return null;
};

export default withPageComponents(withConfigurations(pdpGallery), {
    components: ['pdpImages', 'productInitial', 'pdpPartnerLogos'],
    device: true,
});
