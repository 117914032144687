import React, { useEffect } from 'react';
import { Tabs, Link } from '@hpstellar/core';

import useDeviceInfo from '../../hooks/useDeviceInfo';

import './bv-tabs.less';

const EMPTY_OBJ = {};
const getTabs = (device, tabs) => {
    if (device === 'desktop') {
        return tabs;
    }
    return tabs.map(({ tab }) => {
        const { mobileTitle, title } = tab;
        return { tab: { ...tab, title: mobileTitle || title } };
    });
};

const setBVEventHandlers = (tabs, activeAreaIndex, setActiveAreaIndex) => {
    try {
        const setHandlers = BV => {
            const reviewsIndex = tabs.findIndex(({ tab }) => {
                return tab.sections.includes('reviews');
            });
            const questions = tabs.findIndex(({ tab }) => {
                return tab.sections.includes('questions');
            });
            // Register a function to be called when a BV feature needs to display the R&R element, such as Rating Summary's stars
            BV.swat_reviews.on('show', function () {
                // If the R&R container is hidden (such as behind a tab), put code here to make it visible (open the tab)
                setActiveAreaIndex(reviewsIndex);
            });

            // Register a function to be called when a BV feature needs to
            // display the Q&A element, such as when selecting the number of questions link
            BV.questions.on('show', function () {
                // If the container is hidden (such as behind a tab), put code here to make it visible (open the tab)
                setActiveAreaIndex(questions);
            });
        };
        if (window.BV) {
            setHandlers(window.BV);
        } else {
            window.bvCallback = setHandlers;
        }
    } catch (e) {}
};

export default ({ tabs, transformedSKU, tabState, allReviewsLink, numberOfReview = 0 }) => {
    const { device } = useDeviceInfo(['device']);
    const [activeAreaIndex, setActiveAreaIndex] = tabState;
    const { txt: pdpReviewsLinkText = 'SEE ALL REVIEWS', path: pdpReviewsPath } = allReviewsLink || EMPTY_OBJ;
    useEffect(() => {
        setBVEventHandlers(tabs, activeAreaIndex, setActiveAreaIndex);
    }, [tabs]);
    return (
        <div className={`bv-tab-container ${Math.min(numberOfReview, 8)}-reviews`}>
            <Tabs
                list={getTabs(device, tabs)}
                activeIndex={activeAreaIndex}
                tabsListProps={{
                    type: 'secondary',
                    size: device === 'desktop' ? 'large' : 'small',
                    onClick: setActiveAreaIndex,
                    tabTitleTag: 'h4',
                    dropdownOnMobile: false,
                    dense: device !== 'desktop',
                }}
            />
            {tabs.map(({ tab }, idx) => {
                const { sections = [], hash } = tab;
                const style = { display: idx === activeAreaIndex ? 'block' : 'none' };
                return (
                    <div key={idx} className="bv-tabbed-section" style={style}>
                        {sections.map(sectionKey => {
                            return (
                                <div
                                    key={sectionKey}
                                    data-bv-show={sectionKey}
                                    data-bv-product-id={transformedSKU}
                                ></div>
                            );
                        })}
                    </div>
                );
            })}
            {pdpReviewsPath && (
                <Link
                    className="all-reviews-link"
                    aria-label={pdpReviewsLinkText}
                    to={pdpReviewsPath}
                    variation="body"
                    weight="light"
                >
                    {pdpReviewsLinkText}
                </Link>
            )}
        </div>
    );
};
