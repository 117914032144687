import React from 'react';
import { Typography, Modal } from '@hpstellar/core';
import { Cross } from '@hpstellar/icons';

import RadioButton from '../configure-radio';

import './configuration-drawer.less';

const configurationDrawer = ({
    animationType = 'slide-in-right',
    horizontalAlignment = 'right',
    isOpen,
    onClose = () => {},
    title = 'Choose Your Option',
    subheader,
    className,
    modalClassName,
    HeaderComponent,
    onChange = () => {},
    configurations,
    withBackdrop = true,
    direction,
    error,
    children,
}) => {
    if (children || (configurations && configurations.length > 0)) {
        const selectedOption = (configurations && configurations.find(o => o.checked)) || {};
        return (
            <Modal
                animationType={animationType}
                horizontalAlignment={horizontalAlignment}
                isOpen={isOpen}
                className={`configuration-drawer ${className ? ` ${className}` : ''}`}
                onModalClose={onClose}
                modalClassName={modalClassName}
                withBackdrop={withBackdrop}
            >
                <div className="title-container">
                    <Typography tag="h2" variant="headingTitleSmall" className="title-text">
                        {title}
                    </Typography>
                    <button type="button" className="closeButton" onClick={onClose}>
                        <Cross size="m" />
                    </button>
                </div>
                {(subheader || HeaderComponent) && (
                    <div className="clearfix configuration-header">
                        {subheader && (
                            <Typography tag="h4" variant="headingSubheader" className="headingSubheader">
                                {subheader}
                            </Typography>
                        )}
                        {HeaderComponent && HeaderComponent()}
                    </div>
                )}
                {children ? (
                    <div className="configuration-custom-content">{children}</div>
                ) : (
                    <div className="configuration-radio-group">
                        {configurations.map((c, i) => (
                            <RadioButton
                                key={`${i}-${c.checked}`}
                                label={c.label}
                                title={c.title}
                                info={c.info}
                                checked={c.checked}
                                value={c.value}
                                colorHex={c.colorHex}
                                imageUrl={c.imageUrl}
                                variation="tile"
                                direction={direction}
                                onChange={val => {
                                    onChange(val);
                                    onClose();
                                }}
                            />
                        ))}
                    </div>
                )}
            </Modal>
        );
    }
    return null;
};

export default configurationDrawer;
