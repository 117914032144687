import React from 'react';

import './pdp-details.less';

const ProductDetailsContainer = ({ children, id = 'pdpOverview' }) => {
    return (
        <div className="pdp-details" id={id}>
            {children}
        </div>
    );
};

export default ProductDetailsContainer;
