import React from 'react';
import loadable from '@loadable/component';

import withPageComponents from '../../../page/components/with-page-components';

const SuppliesContainer = loadable(() => import('./similar-supplies-container'));

export * from './utils';

const similarSupplies = ({ productInitial, drawerPosition = 'right', pdpSimilarSupplies, trackCustomMetric }) => {
    const { similarSupplies } = pdpSimilarSupplies || {};
    if (!productInitial || !similarSupplies || similarSupplies.length < 1) {
        return null;
    }
    return (
        <SuppliesContainer
            productInitial={productInitial}
            drawerPosition={drawerPosition}
            pdpSimilarSupplies={pdpSimilarSupplies}
            trackCustomMetric={trackCustomMetric}
        />
    );
};

export default withPageComponents(similarSupplies, { components: ['productInitial', 'pdpSimilarSupplies'] });
