import React from 'react';
import PropTypes from 'prop-types';

import withWindowResize from '../../ui/components/window-resize';
import { Helpers } from '../../core/src/helpers';
import ImageWithPlaceholder from './image-with-placeholder';
import LazyLoadImage from './lazyload-image';
import LCPPrefetch, { getPrefetchImageArray } from './lcp-prefetch';

export const getImageMangerQuery = (impolicy, width) => {
    return {
        impolicy,
        imdensity: 1,
        imwidth: width,
    };
};

/**
A component for optimizing images based on device type. Intended to be used with image servers like Akamai image manager
@examples
```jsx
<AdaptiveImage src="/app/assets/images/product/7DF84AV_1/center_facing.png" offset={300} />
```
@component AdaptiveImage
@import AdaptiveImage
@returns {ReactElement} The rendered component
*/
class AdaptiveImage extends React.PureComponent {
    static defaultProps = {
        mobileWidth: 600,
        tabletWidth: 1023,
        desktopWidth: 1920,
        maxWidth: 1920,
        impolicy: Helpers.DEFAULT_IMAGE_POLICY,
    };

    static propTypes = {
        /** Optional paremeter to use lazy load component that better browser coverage. WARNING: doesn't work if components being skipped for hydration */
        lazyload: PropTypes.bool,
        /** Optional parameter for using either native HTML 5 lazy load or browser compatiable JS lazyloading */
        loading: PropTypes.oneOf(['auto', 'lazy', 'eager']),
        /**
         * max image width for mobile device
         */
        mobileWidth: PropTypes.number,
        /**
         * max width for tablet device
         */
        tabletWidth: PropTypes.number,
        /**
         * max width for desktop device
         */
        desktopWidth: PropTypes.number,
        /**
         * max width for all device types
         */
        maxWidth: PropTypes.number,
    };

    state = {
        imageQuery: {},
    };

    static getDerivedStateFromProps(props, state) {
        const { device, mobileWidth, tabletWidth, desktopWidth, width, src, maxWidth, lazyload, impolicy } = props;
        const { imageQuery } = state;
        //do not update query if no src or if current query width is not greater
        // than the new width, this is to avoid fetching the same image at smaller size
        // This is because the large image has already likely been loaded or requested so there is no point in try to fetch a smaller image
        if (!src || width < imageQuery.imwidth) {
            return null;
        }
        //TODO: for now if lazload is not set, just use the size bases on device type rather than window
        //this is to prevent the client from fetching the image with different params after the client hyrdates the server
        let currentWidth = lazyload && width && width > 1 ? width : desktopWidth;
        let deviceMax = Math.min(
            device === 'mobile' ? mobileWidth : device === 'tablet' ? tabletWidth : desktopWidth,
            maxWidth
        );
        return {
            imageQuery: getImageMangerQuery(impolicy, Math.min(deviceMax, currentWidth)),
        };
    }

    render() {
        const { alt, title, className, imageClassName, lazyload, src, loading, prefetch } = this.props;
        const { imageQuery } = this.state;
        const adaptiveImageSrc = Helpers.purifyUrl(Helpers.addUrlParam(src, imageQuery, true));

        return lazyload ? (
            <LazyLoadImage
                className={className}
                imageClassName={imageClassName}
                src={adaptiveImageSrc}
                alt={alt}
                title={title}
            />
        ) : (
            <>
                <ImageWithPlaceholder
                    className={className}
                    imageClassName={imageClassName}
                    src={adaptiveImageSrc}
                    alt={alt}
                    loading={loading}
                    title={title}
                />
                {prefetch && (
                    <LCPPrefetch images={getPrefetchImageArray(adaptiveImageSrc, [{ href: adaptiveImageSrc }])} />
                )}
            </>
        );
    }
}

export default withWindowResize(AdaptiveImage);
export { AdaptiveImage as TestAdaptiveImage };
