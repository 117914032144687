import React, { useEffect, useCallback, useState } from 'react';
import { CollapseWrapper } from '@hpstellar/core';

import Reviews from '../../../bv/components/bv-reviews';
import withPageComponents from '../../../page/components/with-page-components';
import { Helpers } from '../../../core/src/helpers';
import { NAV_SECTIONS } from '../constants';
import { scrollIntoViewWithOffset } from '../../../hooks/useScrollTarget';
import useLazyScroll from '../../../hooks/useLazyScroll';
const { reviews, reviewsQA } = NAV_SECTIONS;

import './review.less';

export const QANDA_ID = 'reviewsQA';

const ReviewsPlaceHolder = () => <div style={{ minHeight: '100px' }}></div>;
export const DEFAULT_REVIEWS_TABS = [
    {
        tab: {
            hash: reviews.hash,
            title: reviews.title,
            mobileTitle: reviews.mobileTitle,
            gtmValue: 'ratings&reviews',
            sections: ['review_highlights', 'reviews'],
        },
    },
    {
        tab: {
            hash: reviewsQA.hash,
            title: reviewsQA.title,
            mobileTitle: reviewsQA.mobileTitle,
            gtmValue: 'questions&answers',
            sections: ['questions'],
        },
    },
];

//TODO: hacky solution since StickyHeader component does not have on callback for anchor link clicks

const NAVTargets = ({ tabState, tabs, activeTab }) => {
    const [scrollToTarget] = useLazyScroll({ offset: 100 });
    const [activeAreaIndex, setActiveAreaIndex] = tabState;
    const onHashChange = useCallback(event => {
        if (location.hash === `#${reviewsQA.hash}`) {
            setActiveAreaIndex(1);
        } else if (location.hash === `#${reviews.hash}`) {
            setActiveAreaIndex(0);
        }
    }, []);
    useEffect(() => {
        window.addEventListener('hashchange', onHashChange, false);
        return () => {
            window.removeEventListener('hashchange', onHashChange, false);
        };
    }, [onHashChange]);
    useEffect(() => {
        //only reset scroll position when click between reviews and questions. So the hash fragment must be present
        if (!activeTab || ![`#${reviewsQA.hash}`, `#${reviews.hash}`].includes(location.hash)) {
            return () => {};
        }
        const target = document.getElementById(activeTab.hash);
        scrollToTarget(target);
    }, [activeAreaIndex]);
    return tabs.map(({ tab }) => (
        <div key={tab?.hash} id={tab?.hash} data-active={activeTab?.hash === tab?.hash}></div>
    ));
};

const pdpReviews = ({
    productInitial,
    trackCustomMetric,
    tabs = DEFAULT_REVIEWS_TABS,
    pdpSEOReviews,
    pdpReviewsLink,
}) => {
    const reviewsTabState = useState(0);
    const [activeIndex] = reviewsTabState;
    const { tab: activeTab } = tabs[activeIndex];

    return productInitial ? (
        //NOTE: id='reviews' is a dependency on stellar lib
        <>
            <NAVTargets tabState={reviewsTabState} tabs={tabs} activeTab={activeTab} />
            <section className="pdp-reviews" tabindex="0">
                <CollapseWrapper accordionProps={{ isStatic: true, defaultExpanded: true }} desktopTitleTag="h2">
                    <Reviews
                        product={productInitial}
                        trackCustomMetric={trackCustomMetric}
                        tabs={tabs}
                        tabState={reviewsTabState}
                        pdpSEOReviews={pdpSEOReviews}
                        allReviewsLink={pdpReviewsLink}
                    />
                </CollapseWrapper>
            </section>
        </>
    ) : null;
};

pdpReviews.whyDidYouRender = true;

export default withPageComponents(React.memo(pdpReviews), {
    components: ['productInitial', 'pdpSEOReviews', 'pdpReviewsLink'],
});
