import React from 'react';

import ScriptLoader from '../../page/components/script-loader';
import { useBVSettings } from '../../hooks/useSiteConfig';

const BV_URL = 'https://apps.bazaarvoice.com/deployments/hp/main_site/production/en_US/bv.js';
const BV_PRP_URL = 'https://apps.bazaarvoice.com/deployments/hp/seo_prp/production/en_US/bv.js';

const BvScript = ({ templateKey }) => {
    const { bvScript = BV_URL, bvPRPScript = BV_PRP_URL } = useBVSettings();
    return <ScriptLoader url={templateKey === 'reviews' ? bvPRPScript : bvScript} name="bv" />;
};

export default BvScript;
