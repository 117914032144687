import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GalleryModal } from '@hpstellar/core';
import useTrackCustomMetric from '../../../../hooks/useTrackCustomMetric';
import { getAnalyticsVideoName } from '../../../../metrics/metrics-helpers';

import withPageComponents from '../../../../page/components/with-page-components';
import AddOnPrice from '../../../../product/components/product-configurations/addon-price';
import useAddToCart from '../../../../hooks/useAddToCart';
import useProductPrice from '../../../../hooks/useProductPrice';
import useCartState from '../../../../hooks/useCartState';
import { Helpers } from '../../../../core/src/helpers';
import { clickPdpAddToCartWithoutGtmAttributes } from '../../../analytics';
import { getAddon } from '../../../../metrics/metrics-helpers';

import './image-modal.less';

const EMPTY_OBJECT = {};

const removeDimensions = orientation => {
    try {
        return orientation.replace(/\d+x\d+$/, '').trim();
    } catch (e) {
        return orientation;
    }
};

/**
 * Uses zoomImages wherever available
 * @param {*} images
 */
const mapImages = images => {
    const { defaultImages = [], zoomImages = [] } = images || {};
    const zoomImageArray = zoomImages.slice();
    const isParallel = defaultImages.length === zoomImages.length;
    return defaultImages.map((img, idx) => {
        if (img.type !== 'video') {
            if (isParallel) {
                const zoomImage = zoomImages[idx];
                if (zoomImage && zoomImage.url) {
                    return zoomImages[idx];
                }
                return {
                    ...img,
                    isLowResolution: true,
                };
            }
            //if arrays are not parallel, find first zoom image matching the same orientation
            const zoomIndex = zoomImageArray.findIndex(
                zImg => removeDimensions(zImg.orientation) === removeDimensions(img.orientation),
            );
            if (zoomIndex < 0) {
                return {
                    ...img,
                    isLowResolution: true,
                };
            }
            return zoomImageArray.splice(zoomIndex, 1)[0];
        } else if (img.url && /\.mp4(\?.*)?$/.test(img.url)) {
            return {
                ...img,
                videoProps: {
                    sources: [{ src: img.url, type: 'video/mp4' }],
                },
            };
        }
        return img;
    });
};

const getModalData = (product, images, currentPrice, ctoConfig, isReviewsPage) => {
    if (product) {
        const { ctaText, ctoLink, customizeText, isOOS, currency, locale, deal, isComingSoonSku, translations } =
            currentPrice || {};
        const { name, sku, eol, ctaEol, ctaEolUrl, ctaAddToCart, ctaReplacementURL, product_type } = product;
        const eolCTAText = eol && (ctaEol || ctaAddToCart);
        const priceDifference = currentPrice.regularPrice - currentPrice.salePrice;
        const outOfStock = eol
            ? !(ctaEolUrl || ctaReplacementURL)
            : Helpers.isGiftCard(product)
              ? !!(ctoConfig || {}).error
              : !isComingSoonSku && isOOS;
        const price =
            currentPrice && !eol && !isReviewsPage
                ? {
                      currencySymbol: '$',
                      regularPrice: currentPrice.regularPrice,
                      salePrice: currentPrice.salePrice,
                      productType: product.product_type.toLowerCase(),
                      currency,
                      locale,
                      priceDifference,
                      hasActiveDeal: !!deal,
                      style: 'center',
                      translations,
                  }
                : EMPTY_OBJECT;
        return {
            data: {
                images: mapImages(images),
                ratings: {
                    score: product.rating,
                    reviewsCount: product.numReviews,
                },
                title: name,
                price,
                sku: `Product # ${sku}`,
                outOfStock,
            },
            cta: {
                ctaText: eolCTAText || ctaText,
                ctoLink,
                //Gift cards on the PDP should not have customize text
                customizeText: product_type === 'CTO' && customizeText,
            },
        };
    }
    return EMPTY_OBJECT;
};

export default withPageComponents(
    ({ productInitial, images, getGtmActions, imageIndex, setImageIndex, ctoConfig, addOns, ctaProps, vanityUrl }) => {
        let history = useHistory();
        const { sku, product_type, eol, ctaReplacementURL, ctaAddToCart, ctaEolUrl, ctaEol, linkUrl } = productInitial;
        const addonProduct = addOns && getAddon(sku, addOns);
        const { addToCart, goToCTOPage, launchCartFlyout } = useAddToCart(false, { withCTOConfigurations: true });
        const { cartId } = useCartState();
        const { prices } = useProductPrice([productInitial, ...(addonProduct ? [addonProduct] : [])], {
            withCTOConfigurations: true,
        });
        const productPrice = prices[sku];
        const eolLink = eol && (ctaEolUrl || ctaReplacementURL);
        const isOpen = imageIndex > -1;
        const { addText } = ctaProps || EMPTY_OBJECT;
        const isReviewsPage = vanityUrl.indexOf('reviews/') > -1;

        return (
            <AddOnPrice sku={sku} price={productPrice}>
                {({ totalPrice }) => {
                    const { data, cta } = getModalData(productInitial, images, totalPrice, ctoConfig, isReviewsPage);
                    const { hideAddToCart } = productPrice;
                    const trackCustomMetric = useTrackCustomMetric();
                    const [currentIdx, setCurrentIdx] = useState(imageIndex);
                    const onAdd = !hideAddToCart
                        ? () => {
                              if (eol && eolLink) {
                                  history.push(Helpers.getRelativePath(eolLink));
                                  return;
                              }
                              if (isReviewsPage) {
                                  history.push(Helpers.getRelativePath(linkUrl));
                                  return;
                              }
                              if (product_type === 'CTO') {
                                  goToCTOPage(productInitial, 1);
                              } else {
                                  //TODO: temp fix to trigger xsell modal
                                  let mainBtn = document.getElementById('pdpAddToCartBtn');
                                  if (mainBtn) {
                                      // prevents additional addToCart event when clicking on the other button.
                                      clickPdpAddToCartWithoutGtmAttributes(mainBtn);
                                  } else {
                                      addToCart(
                                          productInitial,
                                          1,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          undefined,
                                          { list: 'pdp-gallery' },
                                      ).then(() => {
                                          launchCartFlyout();
                                      });
                                  }
                              }
                          }
                        : undefined;

                    useEffect(() => {
                        try {
                            const { type, url } = data.images[currentIdx];
                            if (isOpen && type === 'video' && url) {
                                trackCustomMetric('videoLoad', {
                                    videoName: getAnalyticsVideoName(url, currentIdx),
                                    videoDuration: 999,
                                });
                            }
                        } catch (e) {}
                    }, [currentIdx, isOpen]);

                    // TODO: CHECK HOW TO IMPLEMENT ADD TO CART, tried different ways to add the gtm attributes, none seem to work and there's 0 documentation on it
                    return (
                        <GalleryModal
                            key={`modalReset-${isOpen}`}
                            className="imageModal"
                            isOpen={isOpen}
                            initialSlide={Math.max(imageIndex, 0)}
                            data={data}
                            onModalClose={() => setImageIndex(-1)}
                            onSlideChange={setCurrentIdx}
                            gtmActions={
                                getGtmActions &&
                                getGtmActions({
                                    prices,
                                    productInitial,
                                    cartId,
                                    selectedAddonSku: addonProduct && addonProduct.sku,
                                })
                            }
                            translations={{ addText: addText || cta.customizeText || cta.ctaText }}
                            addToCartBtnProps={{
                                onClick: onAdd,
                                timeoutSuccess: !totalPrice.isComingSoonSku,
                            }}
                        />
                    );
                }}
            </AddOnPrice>
        );
    },
    { components: ['productInitial'], addOns: true },
);
