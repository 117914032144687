import React from 'react';
import { onEnterClick } from '../../../ui/components/accessible-element';
import withPageComponents from '../../../page/components/with-page-components';
import CompareCheckbox from '../../../compare/components/compare-checkbox';

import './compare-sku.less';

const getCompareProduct = (productInitial, images = {}, prices) => {
    const imageUrl =
        images?.smallImages?.[0]?.url ||
        images?.mediumImages?.[0]?.url ||
        images?.fullImages?.[0]?.url ||
        images?.thumbnailImages?.[0]?.url;

    return {
        ...productInitial,
        title: productInitial.name,
        images: [{ src: imageUrl }],
        price: prices[productInitial.sku],
    };
};

const CompareSku = ({ productInitial, pdpImages, prices }) => {
    if (!productInitial) return null;
    const { eol, sku } = productInitial || {};
    const displaySKU = (sku || '').replace(/^P_/, '');
    return (
        <div className="compare-sku" onKeyUp={onEnterClick(null, true)}>
            {!eol && (
                <CompareCheckbox
                    product={getCompareProduct(productInitial, pdpImages, prices)}
                    btnText={<h4>Add to compare</h4>}
                    selectedText={<h4>Selected to compare</h4>}
                />
            )}
            <span className="sku">Product # {displaySKU}</span>
        </div>
    );
};

export default withPageComponents(CompareSku, {
    components: ['productInitial', 'pdpImages'],
});
