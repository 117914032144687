import React from 'react';
import { Button, Link, PriceBlock } from '@hpstellar/core';
import { Plus } from '@hpstellar/icons';

import withPageComponents from '../../../../page/components/with-page-components';
import useProductPrice from '../../../../hooks/useProductPrice';
import useAddToCart from '../../../../hooks/useAddToCart';
import PDPPriceBlock from '../price-block';
import './enrollment-kit.less';

export const useEnrollmentKit = (productInitial, options) => {
    const { enrollmentKit } = productInitial || {};
    const { addKitText = 'Add Enrollment Kit' } = options || {};
    //TODO: need to update once API has the actual attribute for the learn more link
    const { kit, instantInkEligible } = enrollmentKit || {};
    //only fetch kit price is instantInkEligible is true and kit data is available
    const { prices } = useProductPrice(kit && instantInkEligible ? [kit] : []);
    const { addToCart, launchCartFlyout } = useAddToCart();
    if (!enrollmentKit) {
        return {};
    }
    const { sku: kitSku, learnMoreLink } = kit || {};
    const { btnProps, price } = enrollmentKit;
    const kitPrice = prices[kitSku];
    const { isOOS } = kitPrice || {};
    const { customPrefix } = price || {};
    const enableKitAddToCart = kitPrice && isOOS === false;

    const kitDisplayPrice = enableKitAddToCart ? { customPrefix, ...kitPrice } : price;
    const onClick = enableKitAddToCart ? () => addToCart(kit, 1).then(launchCartFlyout) : undefined;
    const enrollmentKitProps = enableKitAddToCart
        ? {
              children: addKitText,
              onClick,
              to: undefined,
              endIcon: <Plus size="s" />,
              timeoutSuccess: 500,
          }
        : {};

    return {
        kitDisplayPrice,
        enrollmentKitProps,
        btnProps,
        enableKitAddToCart,
        learnMoreLink,
    };
};

export const EnrollmentKit = ({ productInitial, productCTA }) => {
    const { kitDisplayPrice, enrollmentKitProps, btnProps, enableKitAddToCart, learnMoreLink } =
        useEnrollmentKit(productInitial);
    return (
        <>
            <div className="enrollment-kit">
                <PDPPriceBlock className="price-atc" hideRewards={true} priceFullWidth={false} />
                <div className="cta-atc">{productCTA}</div>
                {kitDisplayPrice && <PriceBlock className="price-kit" {...kitDisplayPrice} style={'left'} />}
                <Button className="cta-kit" variation="secondary" {...btnProps} {...enrollmentKitProps} />
            </div>
            {enableKitAddToCart && learnMoreLink && (
                <div>
                    <Link to={learnMoreLink} variation="body" weight="light">
                        Learn more about Instant Ink Enrollment Kit
                    </Link>
                </div>
            )}
        </>
    );
};

export default withPageComponents(EnrollmentKit, { components: ['productInitial'] });
