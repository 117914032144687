import React from 'react';
import { RadioWithLabel, Link, ColorSelection } from '@hpstellar/core';

import PropTypes from 'prop-types';

import AdaptiveImage from '../../shared/components/adaptive-image';
import { Helpers } from '../../core/src/helpers';

import './configure-radio.less';

function RadioButton({
    className,
    infoClassName,
    ariaLabel,
    name,
    label,
    title,
    info,
    colorHex,
    imageUrl,
    value,
    active,
    error,
    offerMessage,
    textSize = 'default',
    content,
    onChange,
    productName,
    to,
    linkText = 'View Details',
    direction = 'row',
    gtmActions,
    variant,
    ...otherProps
}) {
    const containerClass = `configure-radio text-${textSize}${className ? ' ' + className : ''}${
        active ? ' active' : ''
    }`;
    const alt = productName ? `${productName}${label ? ' - ' + label : ''}` : '';
    const hasError = !!error;
    return (
        <RadioWithLabel
            ariaLabel={ariaLabel || label}
            active={active}
            className={`${containerClass} ${variant}`}
            error={error}
            variant={variant}
            {...otherProps}
        >
            <RadioWithLabel.Label className={`radio-container ${direction}`}>
                <div className={`radio-left-container`}>
                    <div className={`radio-left`}>
                        {imageUrl && <AdaptiveImage src={imageUrl} desktopWidth={80} alt={alt} />}
                        {colorHex && (
                            <ColorSelection className="color-swatch" colors={[{ color: colorHex }]} isPreview={true} />
                        )}
                        <div className="radio-label">
                            {title && <b className={'radio-title'}>{title}</b>}
                            {label}
                            {offerMessage && (
                                <div
                                    className="offer-message"
                                    dangerouslySetInnerHTML={Helpers.createMarkup(offerMessage)}
                                />
                            )}
                            {to && (
                                <>
                                    <br />
                                    <Link
                                        aria-label="aria-label placeholder"
                                        target="_blank"
                                        dense
                                        to={to}
                                        variation="body"
                                    >
                                        {linkText}
                                    </Link>
                                </>
                            )}
                        </div>
                    </div>
                    {info && <div className={`radio-info${infoClassName ? ` ${infoClassName}` : ''}`}>{info}</div>}
                </div>
                <RadioWithLabel.Input
                    className={hasError ? 'error-input' : ''}
                    value={value}
                    onChange={e => onChange(e?.target?.value)}
                    gtmActions={gtmActions}
                />
                {content && <div className="radio-custom">{content}</div>}
            </RadioWithLabel.Label>
        </RadioWithLabel>
    );
}

RadioButton.propTypes = {
    /** title text to appear above the label */
    title: PropTypes.string,
    /** Display name for the label of the radio button */
    label: PropTypes.string,
    /** Additional info about radio option */
    info: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.element]),
    /** Value of the radio button */
    value: PropTypes.any.isRequired,
    /** Additional class names */
    className: PropTypes.string,
    /** Additional class names */
    infoClassName: PropTypes.string,
    /** custom aria label */
    ariaLabel: PropTypes.string,
    theme: PropTypes.oneOf(['light', 'dark']),
    /** Variant display types */
    variant: PropTypes.oneOf(['primary', 'secondary']),
    /** HEX value to add a color swatch to the ratio button */
    colorHex: PropTypes.string,
    /** Link to image to be displayed */
    imageUrl: PropTypes.string,
    /** Change font size of radio label */
    textSize: PropTypes.oneOf(['default', 'small']),
    /** custom content */
    content: PropTypes.element,
    broad: PropTypes.bool,
    /** onChange event of toggling radio button to active */
    onChange: PropTypes.func,
    direction: PropTypes.oneOf(['row', 'column']),
};

RadioButton.defaultProps = {
    theme: 'light',
    variant: 'primary',
    broad: true,
};

export default React.memo(RadioButton);
