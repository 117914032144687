import React from 'react';
import { Helmet } from 'react-helmet';

import { Helpers } from '../../core';
import withPageComponents from '../../page/components/with-page-components';
/**
 * Memoized function to get prefetch image array
 */
export const getPrefetchImageArray = Helpers.memoize(
    (key, images, cb) => {
        try {
            if (!images || !Array.isArray(images)) return null;
            return cb ? images.map(cb) : images;
        } catch (e) {
            return null;
        }
    },
    key => key
);

/**
 * LCP prefetch component to preload LCP image
 * @param {string} href - image source
 * @returns {JSX.Element}
 */
export default withPageComponents(
    ({ images, width, matchOne }) => {
        if (!images || !Array.isArray(images)) return null;
        let match = false;
        return (
            <Helmet>
                {images.map((image, index) => {
                    const { href, minWidth } = image;
                    if (!href || (minWidth && width < minWidth) || (matchOne && match)) {
                        return null;
                    }
                    match = true;
                    return <link rel="preload" as="image" href={href} key={index} />;
                })}
            </Helmet>
        );
    },
    { device: true }
);
