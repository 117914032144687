import React from 'react';
import { Configurations } from '@hpstellar/core';
import './printer-cartridge-pageyield.less';

export const getPricePerPage = (pageYield, price) => {
    //TODO: re-enable this when we have a consistent source for pageYield as a number
    return null;
    if (!pageYield || !price) {
        return null;
    }
    try {
        const yieldNumber = isNaN(pageYield)
            ? (pageYield.match(/(?<=~)([\d,\.]+)(?= pages)/gi) || []).reduce((minYield, text) => {
                  //in the case of multi ink colors with different yield numbers, find the minimum
                  const asNumber = text.replace(/\,/gi, '') * 1;
                  if (!isNaN(asNumber) && asNumber < (minYield || Number.MAX_VALUE)) {
                      return asNumber;
                  }
                  return minYield;
              }, null)
            : pageYield;
        return yieldNumber && yieldNumber > 0 ? price.salePrice / yieldNumber : null;
    } catch (e) {
        return null;
    }
};

const PrinterCartridgePageYield = ({ product, price, dividerPosition }) => {
    const { pageYield } = product.overviewAttributes || {};
    if (pageYield) {
        const { value, label } = pageYield;
        const pricePerPage = getPricePerPage(value, price);
        return (
            <Configurations
                label={label}
                dividerPosition={dividerPosition}
                className="cartridge-page-yield"
                content={
                    <div className="page-yield-info">
                        <span>{value}</span>
                        {pricePerPage && !isNaN(pricePerPage) && <span>{`~$${pricePerPage.toFixed(2)}/page`}</span>}
                    </div>
                }
            />
        );
    }
    return null;
};

export default PrinterCartridgePageYield;
