import React, { useCallback } from 'react';
import {Button,Link} from '@hpstellar/core';
import { useSmoothScroll, useStickyHeaderOffset } from '@hpstellar/core/hooks';

import { NAV_SECTIONS } from '../constants';
import withPageComponents from '../../../page/components/with-page-components';
import useLoadLazyComponent from '../../../shared/components/above-the-fold-only-server-render/useLoadLazyComponent';
import useLazyScroll from '../../../hooks/useLazyScroll';
const { reviews } = NAV_SECTIONS;

const onClick = (scrollToTarget, hash, loadReviews) => index => {
    if (hash) {
        try {
            loadReviews && loadReviews();
            const target = document.getElementById(hash);
            scrollToTarget(target);
            target.focus();
        } catch (e) {}
    }
};

const SimilarProductsLink = ({
    pdpSimilarProducts,
    ctaText = 'SEE SIMILAR PRODUCTS',
    hash = 'similarProducts',
    forceDisplay,
    type = 'link',
    gtmAttributes,
}) => {
    const { products } = pdpSimilarProducts || {};
    const [scrollToTarget] = useLazyScroll({});
    const offset = useStickyHeaderOffset();
    const [loadLazyComponent] = useLoadLazyComponent({ offset });
    const loadReviews = useCallback(() => {
        loadLazyComponent(reviews.key);
    }, []);
    if (!forceDisplay && (!products || products.length < 0)) {
        return null;
    }
    if (type === 'button') {
        return (
            <Button
                variation="secondary"
                size="small"
                onClick={onClick(scrollToTarget, hash, loadReviews)}
                onMouseEnter={loadReviews}
                className="similar-products-link"
                {...(gtmAttributes || {})}
            >
                {ctaText}
            </Button>
        );
    }
    return (
        <Link onClick={onClick(scrollToTarget, hash)} className="similar-products-link" {...(gtmAttributes || {})}>
            {ctaText}
        </Link>
    );
};

export default withPageComponents(SimilarProductsLink, { components: ['pdpSimilarProducts'] });
