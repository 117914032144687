import React from 'react';
import { HpRewardBadge } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import AddOnPrice from '../../../product/components/product-configurations/addon-price';
import ProductPriceBlock from '../../../product/components/product-price/product-price-block';

const PriceBlock = ({ productInitial, hidePrice, hideRewards, priceFullWidth, className }) => {
    if (!productInitial) return null;

    const { eol, rewardsBadge } = productInitial;
    const hasRewardsLabel = rewardsBadge && rewardsBadge.label && !hideRewards;
    //use custom class name for full width to avoid 50% max width
    const isFullWidth = typeof priceFullWidth !== 'undefined' ? priceFullWidth : !hasRewardsLabel;

    return (
        !eol && (
            <div className={className}>
                {!hidePrice && (
                    <AddOnPrice sku={productInitial.sku}>
                        {({ addOnPrice }) => {
                            return (
                                <ProductPriceBlock
                                    className={`pdp-price ${isFullWidth ? ' full-width' : ''}`}
                                    product={productInitial}
                                    addOnPrice={addOnPrice}
                                    style={'left'}
                                    withCTOConfigurations={true}
                                    priceTag="h3"
                                    hasPriceSeoText
                                />
                            );
                        }}
                    </AddOnPrice>
                )}
                {hasRewardsLabel && (
                    <HpRewardBadge
                        className="rewardsBadge"
                        message={rewardsBadge.label}
                        color={'purple'}
                        tooltipContent={{ title: rewardsBadge.tooltipTitle, description: rewardsBadge.tooltipBody }}
                    />
                )}
            </div>
        )
    );
};

export default withPageComponents(PriceBlock, {
    components: ['productInitial'],
});
