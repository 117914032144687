import React, { useCallback } from 'react';
import { Configurations } from '@hpstellar/core';

import CTOColorSelector from '../cto-color-selector';
import CTOConfigurations from '../cto-configurations';
import ConfigurationBadge from '../cto-configurations/configuration-badge';
import SimilarSupplies from '../similar-supplies';
import SimilarProductsSection from './similar-products-section';
import PrinterCartridgeColor from '../similar-supplies/printer-cartridge-color';
import { getPricePerPage } from '../similar-supplies/printer-cartridge-pageyield';
import withPageComponents from '../../../page/components/with-page-components';
import useProductPrice from '../../../hooks/useProductPrice';
import { NAV_SECTIONS } from '../constants';
import CompareSku from './compare-sku';
const { techSpecs: specsNAV } = NAV_SECTIONS;

import './specs-section.less';

const getSpecsLabel = product => {
    const { product_type } = product;
    let label = undefined; //'specifications';
    if (product_type === 'BUNDLE') {
        return 'INCLUDED';
    }
    return label;
};

const OverViewLabels = ({ overViewLabels, price }) => {
    if (!overViewLabels || overViewLabels.length < 1) {
        return null;
    }
    return (
        <div className="featured-spec-container">
            {overViewLabels.map((overview, idx) => {
                const { label, value } = overview;
                if (!value) {
                    return null;
                }
                const specMeta = [];
                try {
                    const pricePerPage = label.toLowerCase() === 'page yield' ? getPricePerPage(value, price) : null;
                    if (pricePerPage && !isNaN(pricePerPage)) {
                        !isNaN(pricePerPage) && pricePerPage > 0 && specMeta.push(`~$${pricePerPage.toFixed(2)}/page`);
                    }
                } catch (e) {}
                return (
                    <span className="featured-spec" key={`${label}-${idx}`}>
                        {label && <span className={'spec-label'}>{label}</span>}
                        {value}
                        {specMeta.length > 0 && ` | ${specMeta.join(' | ')}`}
                    </span>
                );
            })}
        </div>
    );
};

export const KeySellingPoints = withPageComponents(
    ({ productInitial, pdpTechSpecs }) => {
        const { keyPoints, product_type } = productInitial;
        const specsLabel = getSpecsLabel(productInitial);
        let bundleText;
        const keyPointsData = keyPoints.reduce((r, ksp, idx) => {
            if (product_type === 'BUNDLE' && idx === 0 && /^save.*when.*bundle/gi.test(ksp)) {
                bundleText = ksp;
            } else {
                r.push(ksp);
            }
            return r;
        }, []);
        const { technical_specifications = [], highlights = [], datasheets = [] } = pdpTechSpecs || {};
        const showSpecsLink = technical_specifications.length > 0 || highlights.length > 0 || datasheets.length > 0;
        const seeAllLinkClick = useCallback(() => {
            try {
                const accordionTarget = document.getElementById(specsNAV.mobileHash);
                const sectionTarget = document.getElementById(specsNAV.hash);
                const isExpanded = accordionTarget && accordionTarget.getAttribute('aria-expanded') === 'true';
                if (accordionTarget && !isExpanded) {
                    accordionTarget.click();
                } else {
                    sectionTarget.scrollIntoView();
                }
            } catch (e) {}
        });

        return (
            <>
                <Configurations
                    label={specsLabel}
                    tally={bundleText}
                    dividerPosition="top"
                    className={`configComp specs${product_type ? `-${product_type.toLowerCase()}` : ''}${
                        specsLabel ? `-${specsLabel.toLowerCase().replace(/\s+/gi, '-')}` : ' no-label'
                    }`}
                    content={keyPointsData}
                    configListLimit={showSpecsLink ? 1 : undefined}
                    seeAllLinkProps={{ href: showSpecsLink ? `#${specsNAV.hash}` : null, onClick: seeAllLinkClick }}
                />
            </>
        );
    },
    { components: ['pdpTechSpecs'] },
);

const specsSection = ({ category, productInitial, pdpCTOConfiguration, pdpShadowCTO, v2 }) => {
    const { prices } = useProductPrice([productInitial]);
    if (productInitial) {
        const { keyPoints, overViewLabels, sku } = productInitial;
        const { configurations = [] } = pdpCTOConfiguration || {};
        const price = prices[sku];
        const showKeyPoints = keyPoints && keyPoints.length > 0 && configurations.length < 1;
        const { shadowCTOEnabled } = pdpShadowCTO || {};
        if (shadowCTOEnabled) {
            return null;
        }

        return (
            <div className="pdp-specs-section">
                {v2 ? (
                    <>
                        <OverViewLabels overViewLabels={overViewLabels} price={price} />
                        <PrinterCartridgeColor product={productInitial} dividerPosition="top" />
                        {showKeyPoints && <KeySellingPoints productInitial={productInitial} />}
                        <CTOColorSelector />
                        <CTOConfigurations />
                        <CompareSku prices={prices} />
                        <SimilarProductsSection />
                        <SimilarSupplies />
                    </>
                ) : (
                    <>
                        <ConfigurationBadge />
                        <OverViewLabels overViewLabels={overViewLabels} price={price} />
                        <PrinterCartridgeColor product={productInitial} dividerPosition="top" />
                        {showKeyPoints && <KeySellingPoints productInitial={productInitial} />}
                        <SimilarProductsSection />
                        <SimilarSupplies />
                        <CTOConfigurations />
                        <CTOColorSelector />
                    </>
                )}
            </div>
        );
    }
    return null;
};

export default withPageComponents(specsSection, {
    components: ['productInitial', 'pdpCTOConfiguration', 'pdpShadowCTO'],
});
