import React from 'react';
import { Violator } from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import withConfigurations from '../../../product/components/product-configurations';

export const getSelectedVariant = (variants, value) => {
    if (!variants) {
        return {};
    }
    return (
        variants.find(c => {
            const { value: cValue } = c;
            return cValue === value;
        }) || variants[0]
    );
};
const EMPTY_OBJ = {};
const ctoConfigurationBadge = ({ productInitial, pdpCTOConfiguration, ctoConfigurations, variation = 'product' }) => {
    const { sku, productBadge } = productInitial || EMPTY_OBJ;
    const { configurations: ctoConfigList } = pdpCTOConfiguration || EMPTY_OBJ;
    const ctoConfig = ctoConfigurations[sku] || {};
    const selectedVariant = getSelectedVariant(ctoConfigList, ctoConfig.configCatentryId);
    const badgeText = selectedVariant.productBadges || productBadge;
    if (!badgeText) {
        return null;
    }
    return (
        <Violator
            style={{ marginBottom: '1rem' }}
            className="configuration-badge"
            value={badgeText}
            variation={variation}
        />
    );
};

export default withConfigurations(
    withPageComponents(ctoConfigurationBadge, { components: ['productInitial', 'pdpCTOConfiguration'] })
);
