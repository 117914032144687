import React from 'react';

import withConfigurations from './index';
import { PriceContainer } from '../product-price/with-product-price';

const DEFAULT_PRICE_OBJ = {
    regularPrice: 0,
    salePrice: 0,
};

const addPriceObjects = (priceObj, addOnPrice) => {
    let { regularPrice, salePrice, ...otherAttr } = priceObj;
    regularPrice += addOnPrice.regularPrice;
    salePrice += addOnPrice.salePrice;
    return {
        regularPrice,
        salePrice,
        ...otherAttr,
    };
};

/**
 *
 * @param {*} prices
 * @param {*} addOns
 */
const getAddOnPrice = (addOns = [], prices) => {
    return addOns.reduce(
        (totalPrice, addOn) => {
            const { product, qty = 0 } = addOn;
            const price = prices[product.sku];
            totalPrice.regularPrice += price.regularPrice * qty;
            totalPrice.salePrice += price.salePrice * qty;
            return totalPrice;
        },
        {
            regularPrice: 0,
            salePrice: 0,
        },
    );
};

/**
 * Map product to it's currently set AddOn to reciece the total price of the AddOns
 */
const AddOnPrice = ({ sku, addOns, price, children }) => {
    if (sku) {
        const addOnProducts = (addOns[sku] || []).map(({ product }) => product);
        return (
            <PriceContainer products={addOnProducts}>
                {({ prices }) => {
                    const addOnPrice = getAddOnPrice(addOns[sku], prices);
                    const totalPrice = price ? addPriceObjects(price, addOnPrice) : addOnPrice;
                    return children({ addOnPrice, totalPrice, price });
                }}
            </PriceContainer>
        );
    } else {
        return children({ addOnPrice: DEFAULT_PRICE_OBJ });
    }
};

export default withConfigurations(AddOnPrice);
