import React, { useState } from 'react';
import { Configurations, Button } from '@hpstellar/core';
import { MetricsElement } from 'react-metrics';

import withPageComponents from '../../../page/components/with-page-components';
import withConfigurations from '../../../product/components/product-configurations';
import ConfigurationDrawer from '../../../ui/components/configuration-drawer';

import CTOConfigurationCard from './cto-configuration-card';

import './cto-configurations.less';

export const getConfigurations = (variants, catEntryId) => {
    return (variants || []).map((v, i) => {
        const { configCatentryId, ...rest } = v;
        return {
            value: configCatentryId,
            checked: catEntryId ? configCatentryId === catEntryId : i === 0,
            ...rest,
        };
    });
};

export const getSelectedVariant = (variants, value) => {
    return (
        variants.find(c => {
            const { value: cValue } = c;
            return cValue === value;
        }) || variants[0]
    );
};

const onChange = (sku, setCTOConfiguration, isOpen, closeDrawer, trackCustomMetric, configurations) => value => {
    const selectedVariant = getSelectedVariant(configurations, value);
    const { value: configCatentryId, salePrice, regularPrice, fullSpecs } = selectedVariant || {};
    selectedVariant &&
        setCTOConfiguration(sku, {
            configCatentryId,
            price: { salePrice, regularPrice },
            isNotDefaultConfig: value !== configurations[0]?.value,
            fullSpecs,
        });
    closeDrawer(false);
};

const CTOActionButton = ({ value, onClick, ctaText, configCount, className, element = 'div' }) => {
    return (
        <MetricsElement
            element={element}
            data-metrics-event="e_linkClick"
            data-metrics-link-placement="product"
            data-metrics-link-id={`cto-config-picker-${value}`}
            className={className}
        >
            <Button size={'small'} variation="tertiary" onClick={onClick}>
                {ctaText}
                {configCount > 1 ? ` (${configCount - 1})` : undefined}
            </Button>
        </MetricsElement>
    );
};

const ctoConfigurations = ({
    productInitial,
    pdpTechSpecs,
    pdpCTOConfiguration,
    drawerPosition = 'right',
    ctoConfigurations,
    setCTOConfiguration,
    label = 'Selected Configuration',
    ctaText = 'HP Recommended Configurations',
    trackCustomMetric,
    device,
}) => {
    const [isOpen, closeDrawer] = useState(false);
    if (!productInitial || !pdpCTOConfiguration || (pdpCTOConfiguration.configurations || []).length < 1) {
        return null;
    }
    const { sku, keyPoints } = productInitial;
    const { technical_specifications = [], highlights = [] } = pdpTechSpecs || {};
    const { configurations: ctoConfigList } = pdpCTOConfiguration;
    const ctoConfig = ctoConfigurations[sku] || {};
    const configurations = getConfigurations(ctoConfigList, ctoConfig.configCatentryId);
    const selectedVariant = getSelectedVariant(configurations, ctoConfig.configCatentryId);
    const isMobile = device === 'mobile';
    const ctoActionProps = {
        onClick: () => closeDrawer(!isOpen),
        value: selectedVariant.value,
        ctaText: ctaText,
        configCount: ctoConfigList.length,
    };
    const showSpecsLink = technical_specifications.length > 0 || highlights.length > 0;
    const closeModal = () => closeDrawer(false);
    return (
        <>
            <Configurations
                className={`configComp recommended-config${isMobile ? ' mobile' : ''}`}
                dividerPosition="top"
                label={label}
                content={(selectedVariant.ksps || []).length > 0 ? selectedVariant.ksps : keyPoints}
                action={!isMobile && <CTOActionButton {...ctoActionProps} />}
                configListLimit={showSpecsLink ? 1 : undefined}
                seeAllLinkProps={{ href: showSpecsLink ? '#techSpecs' : null }}
            />
            {isMobile && (
                <CTOActionButton className={'cto-configuration-action-mobile'} element="div" {...ctoActionProps} />
            )}
            <ConfigurationDrawer
                title="Choose Your Configuration"
                isOpen={isOpen}
                onClose={closeModal}
                className="cto-configurations"
                subheader={`SPECIFICATIONS AVAILABLE (${ctoConfigList.length})`}
                drawerPosition={drawerPosition}
            >
                <CTOConfigurationCard
                    options={configurations}
                    selectedOption={selectedVariant}
                    onChange={onChange(
                        sku,
                        setCTOConfiguration,
                        isOpen,
                        closeDrawer,
                        trackCustomMetric,
                        configurations,
                    )}
                    closeModal={closeModal}
                />
            </ConfigurationDrawer>
        </>
    );
};

export default withConfigurations(
    withPageComponents(ctoConfigurations, {
        components: ['productInitial', 'pdpCTOConfiguration', 'pdpTechSpecs'],
        device: true,
    }),
);
