export const NAV_SECTIONS = {
    reviews: {
        title: 'Reviews',
        key: 'pdpReviews',
        hash: 'reviews',
        mobileTitle: 'Reviews',
    },
    reviewsQA: {
        title: 'Questions & Answers',
        mobileTitle: 'Questions',
        key: 'questions',
        hash: 'questions',
        navTitle: 'Q&A',
    },
    techSpecs: {
        hash: 'techSpecs',
        mobileHash: 'techSpecsHeader',
    },
};
