import React from 'react';
import { Configurations } from '@hpstellar/core';

const PrinterCartridgeColor = ({ product, dividerPosition }) => {
    const { printerCartridgeColor } = product.overviewAttributes || {};
    if (printerCartridgeColor) {
        const { label, value, content } = printerCartridgeColor;
        return (
            <Configurations
                label={label}
                customColor={value}
                dividerPosition={dividerPosition}
                className="configComp cartridge-color"
                content={content}
            />
        );
    }
    return null;
};

export default PrinterCartridgeColor;
