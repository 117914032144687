import React, { useState, useEffect } from 'react';
import {ColorSelection,Configurations} from '@hpstellar/core';

import withPageComponents from '../../../page/components/with-page-components';
import withConfigurations from '../../../product/components/product-configurations';
import { getColors } from '../../../product/get-product-props';

import './cto-color-selector.less';

const onChange = (sku, setCTOConfiguration, colorCTOs) => (e, selectedIndex) => {
    const selectedVariant = colorCTOs[selectedIndex];
    //if index not found assume default is 0
    const defaultIndex = Math.max(
        colorCTOs.findIndex(c => c.default === 'yes'),
        0
    );
    if (selectedVariant) {
        setCTOConfiguration(sku, {
            color: selectedVariant.name,
            isNotDefaultColor: selectedIndex !== defaultIndex,
        });
    }
};

const ctoColorPicker = ({
    productInitial,
    pdpCTOColorOptions,
    ctoConfig,
    label = 'Color',
    setCTOConfiguration,
    trackCustomMetric,
}) => {
    const [isOpen, closeDrawer] = useState(false);

    if (!productInitial || (pdpCTOColorOptions || []).length < 1) {
        return null;
    }
    const { sku } = productInitial;
    const selectedIndex = pdpCTOColorOptions.findIndex(c => c.name === ctoConfig.color);
    const colors = getColors(pdpCTOColorOptions, selectedIndex);
    const activeIndexes = [selectedIndex > 0 ? selectedIndex : 0];

    return (
        <Configurations
            className="configComp cto-color-selector"
            dividerPosition="top"
            label={label}
            content={
                <ColorSelection
                    colors={colors}
                    maxColorAmount={colors.length}
                    onChange={onChange(sku, setCTOConfiguration, pdpCTOColorOptions)}
                    activeIndexes={activeIndexes}
                />
            }
        />
    );
};

export default withPageComponents(withConfigurations(ctoColorPicker), {
    components: ['productInitial', 'pdpCTOColorOptions'],
});
