import React from 'react';
import { Typography, GlobalLink, Violator } from '@hpstellar/core';

import AboveTheFoldOnlyServerRender from '../../../shared/components/above-the-fold-only-server-render';
import BvScript from '../../../bv/components/bv-script';
import BvRating from '../../../bv/components/bv-rating';
import { NAV_SECTIONS } from '../constants';
import withPageComponents from '../../../page/components/with-page-components';
import SustainabilityLogo from '../../../product/components/sustainability-logo';
import ConditionalWrapper from '../../../shared/components/conditional-wrapper';
import ConfigurationBadge from '../cto-configurations/configuration-badge';
import { Helpers } from '../../../core/src/helpers';
import TypographyVisId from '@hpstellar/core/visId/Typography';
const { reviews } = NAV_SECTIONS;
import './title-section.less';

const focusReviewsSection = () => {
    try {
        document.getElementById('ratings-summary').click();
    } catch (e) {}
};
const EMPTY_OBJ = {};

export const ProductTitle = withPageComponents(
    ({ seo, productInitial, titleLinkProps, typographyProps = EMPTY_OBJ }) => {
        const { h1Tag } = seo || EMPTY_OBJ;
        const { to: titleTo, useRelativePath, ...restTitleLinkProps } = titleLinkProps || EMPTY_OBJ;
        return (
            <ConditionalWrapper
                condition={!!titleTo}
                wrapper={children => (
                    <GlobalLink
                        to={useRelativePath ? Helpers.getRelativePath(titleTo) : titleTo}
                        {...restTitleLinkProps}
                    >
                        {children}
                    </GlobalLink>
                )}
            >
                <Typography
                    className="pdp-title"
                    variant="titleL"
                    tag={'h1'}
                    dangerouslySetInnerHTML={Helpers.createMarkup(h1Tag || productInitial.name)}
                    {...typographyProps}
                />
            </ConditionalWrapper>
        );
    },
    { components: ['productInitial'], seo: true },
);

export const ReviewSection = withPageComponents(
    ({ productInitial, bazaarVoiceProps = {}, templateKey, device }) => {
        const { renderOn = 'load', idleBuffer = 1000, robots = true } = bazaarVoiceProps || EMPTY_OBJ;

        return (
            <>
                {/**Load BV script during an idle period*/}
                <AboveTheFoldOnlyServerRender
                    robots={robots}
                    renderOn={renderOn}
                    idleBuffer={idleBuffer}
                    componentKey={reviews.key}
                >
                    <BvScript sku={productInitial.sku} templateKey={templateKey} />
                </AboveTheFoldOnlyServerRender>
                <div className={'review-section'}>
                    <BvRating sku={productInitial.sku} />
                    {productInitial.usageLabel && device !== 'mobile' && <Violator value={productInitial.usageLabel} />}
                </div>
            </>
        );
    },
    { components: ['productInitial'], device: true },
);

const titleSection = ({
    productInitial,
    bazaarVoiceProps = {},
    titleLinkProps,
    templateKey,
    pdpShadowCTO,
    device,
    showFor = [],
    v2,
}) => {
    const { shadowCTOEnabled } = pdpShadowCTO || {};
    const { overviewAttributes } = productInitial || EMPTY_OBJ;
    const { nextGenTagline } = overviewAttributes || EMPTY_OBJ;

    if (!showFor || (showFor.length > 0 && !showFor.includes(device)) || !productInitial) {
        return null;
    }

    return (
        <div className={`pdp-title-section${v2 ? ' v2' : ''}`}>
            {!v2 && (
                <ReviewSection
                    productInitial={productInitial}
                    bazaarVoiceProps={bazaarVoiceProps}
                    templateKey={templateKey}
                    device={device}
                />
            )}
            {!v2 && (
                <SustainabilityLogo
                    sustainabilityAttribute={productInitial.sustainability_logo_attribute}
                    enableTooltip={true}
                />
            )}
            {!shadowCTOEnabled && <ProductTitle titleLinkProps={titleLinkProps} />}
            {nextGenTagline && (
                <TypographyVisId className="title-tagline" variant={!v2 ? 'subtitle' : 'h5'}>
                    {nextGenTagline}
                </TypographyVisId>
            )}
            {v2 && <ConfigurationBadge />}
        </div>
    );
};

export default withPageComponents(titleSection, {
    components: ['product', 'productInitial', 'pdpShadowCTO'],
    device: true,
});
