import { useDispatch } from 'react-redux';
import { loadLazyComponent } from './lazy-load-actions';

/**
 * @name useLoadLazyComponent
 * @returns {Array.<function>} -Returns dispatch function
 */
export default function useLoadLazyComponent() {
    const dispatch = useDispatch();

    return [componentKey => dispatch(loadLazyComponent(componentKey))];
}
