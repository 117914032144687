import React from 'react';

import { RadioWithLabel, Violator, Ratings, Typography, PriceBlock } from '@hpstellar/core';

import { withError } from '../../../shared/components/error-boundary';

import './cto-configuration-card.less';

const ConfigCard = ({ config }) => {
    const {
        value,
        name,
        configName,
        productBadges,
        rating,
        numReviews,
        sku,
        ksps,
        regularPrice,
        salePrice,
        product_type = 'CTO',
    } = config;
    const productType = product_type.toLowerCase();
    return (
        <div className="cto-configuration-card">
            <Violator small value={configName} className="config-name" />
            <Violator small value={productBadges} variation="product" className="config-badge" />
            {typeof rating === 'number' && <Ratings reviewsCount={numReviews} score={rating} />}
            <Typography tag="h3" variant="titleS" className="product-name">
                {name}
            </Typography>
            {(ksps || []).map((spec, idx) => (
                <Typography variant="list" key={idx} className="spec">
                    {spec}
                </Typography>
            ))}
            <PriceBlock
                size="medium"
                style="left"
                priceDifference={regularPrice - salePrice}
                salePrice={salePrice}
                regularPrice={regularPrice}
                productType={productType}
            />
        </div>
    );
};

export default withError(({ options = [], selectedOption, onChange, closeModal }) => {
    return (
        <>
            <div className="cto-configuration-container">
                {options.map((option, i) => {
                    const { value, checked, gtmActions } = option;
                    return (
                        <RadioWithLabel
                            key={`${value}-${checked}-${i}`}
                            checked={checked}
                            className={checked && 'active'}
                            broad
                        >
                            <RadioWithLabel.Label className="radioLabel">
                                <ConfigCard config={option} />
                                <RadioWithLabel.Input
                                    value={value}
                                    gtmActions={checked ? null : gtmActions}
                                    onChange={e => {
                                        onChange(e?.target?.value);
                                        closeModal();
                                    }}
                                />
                            </RadioWithLabel.Label>
                        </RadioWithLabel>
                    );
                })}
            </div>
        </>
    );
});
