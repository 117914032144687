import React from 'react';
import { Typography } from '@hpstellar/core';

import SimilarProductsLink from './similar-products-link';
import withPageComponents from '../../../page/components/with-page-components';

import './similar-products-section.less';

export default withPageComponents(
    ({ pdpSimilarProductsLink, pdpSimilarSupplies, productInitial }) => {
        const { eol } = productInitial || {};
        //render nothing if no similar supplies of if there is the similar products selector available
        if (!pdpSimilarProductsLink || (pdpSimilarSupplies && pdpSimilarSupplies.length > 0) || eol) {
            return null;
        }
        const { text = "Can't find what you are looking for?", ctaText, gtmAttributes } = pdpSimilarProductsLink;
        return (
            <div className="similar-products-link-container">
                <Typography tag="p" variant="bodySmall">
                    {text}
                </Typography>
                <SimilarProductsLink
                    forceDisplay={true}
                    ctaText={ctaText}
                    type="button"
                    gtmAttributes={gtmAttributes}
                />
            </div>
        );
    },
    { components: ['pdpSimilarProductsLink', 'pdpSimilarSupplies', 'productInitial'] }
);
