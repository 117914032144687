import React from 'react';
import { Button, SkeletonLoader } from '@hpstellar/core';
import { withRouter } from 'react-router-dom';
import { Plus, ArrowRight } from '@hpstellar/icons';

import QuantityPicker from './quantity-picker';
import { Helpers } from '../../core/src/helpers';
import { withError } from '../../shared/components/error-boundary';
import Link from '../../shared/components/hyperlink';
import { withPurchaseButton } from './purchase-button-container';
import CTOAddToCart, { CTOAddToCartComponent } from './cto-add-to-cart';
import AddToQuote from './add-to-quote';
import PurchaseModal from './purchase-modal';
import { addGtmAddToCart, addGtmCustomizeAndBuy } from '../../metrics/metrics-helpers';

const EMPTY_OBJECT = {};
const suppliesSearchUrl = `${process.env.BASENAME}/cv/supplies-search`;
const NO_PRICE = {
    PRT: 1,
    EXT: 1,
};

const PurchaseButtonSkeleton = () => (
    <div className="purchase-btn-container">
        <SkeletonLoader className="purchase-btn-loader" />
    </div>
);

class PurchaseButton extends React.Component {
    state = {
        quantity: 0,
        customModalOnce: true,
        btnBusy: false,
    };

    static defaultProps = {
        onClick: null,
        product: {},
        addOns: [],
        CTOQuery: {},
        forceOOS: false,
        enableCTOAddToCart: false,
        disableXsellPopup: false,
        isV2: true,
        color: 'primary',
        size: 'large',
    };

    static getDerivedStateFromProps(props, state) {
        //don't change quantity after initial load
        if (state.quantity > 0) {
            return null;
        }

        if (props.isMultiple) {
            return { quantity: props.qty };
        } else {
            return { quantity: 1 };
        }
    }

    componentDidMount() {
        const { price, product } = this.props;
        const prdClass = product.product_type || product.prdClass;
        if (price.regularPrice === '' && product.itemId && !(prdClass in NO_PRICE)) {
            this.props.fetchPrices(product.itemId);
        }
    }

    componentDidUpdate(prevProps) {
        const { price, product } = this.props;
        const prdClass = product.product_type || product.prdClass;
        if (prevProps.product.itemId !== product.itemId && price.regularPrice === '' && !(prdClass in NO_PRICE)) {
            this.props.fetchPrices(product.itemId);
        }
    }

    purchase = (event, openModal, customData, onPurchase) => {
        const {
            price,
            product,
            addToCart,
            addOns,
            variant,
            xSellOpenedSkus = EMPTY_OBJECT,
            disableXsellPopup,
            forceOOS,
            removeExistingAssociation,
            launchCartFlyout,
            baseProductSku,
            serialNumber,
            xsellType,
            metrics,
            getConditionalMetrics,
            confirmAddToCart,
        } = this.props;
        const { quantity } = this.state;
        const { Devdependency } = product || EMPTY_OBJECT;
        const { suppliesLinkUrl, pm_producttype } = product.attributes || EMPTY_OBJECT;
        const prdClass = product.product_type || product.prdClass;

        // TEMP CODE SINCE GTM ISN'T IMPLEMENTED IN PRODUCTION
        let conditionalMetrics = {};
        try {
            conditionalMetrics = getConditionalMetrics();
        } catch (e) {}

        if (forceOOS || (price.isComingSoonSku && price.isOOS)) {
            //force OOS should override all other purchase button functionality
            return;
        } else if (price.preOrder) {
            return (
                confirmAddToCart &&
                confirmAddToCart('preorder', [product, quantity, addOns, variant, baseProductSku, xsellType, metrics])
            );
        } else if (suppliesLinkUrl) {
            const { pdpConfig = EMPTY_OBJECT } = product;
            const { pdpDetails = EMPTY_OBJECT } = pdpConfig;
            if (pdpDetails.replacementProduct) {
                window.location = `${suppliesSearchUrl}?searchTerm=${product.sku}`;
            } else {
                window.location = suppliesLinkUrl;
            }
        } else if (prdClass === 'CTO' && !product.eol) {
            this.customizeAndBuy();
        } else if (Helpers.isECarepack(product) && !(baseProductSku || serialNumber) && Devdependency !== 'NO') {
            confirmAddToCart('ecarepack', [product, quantity, addOns, variant, baseProductSku, xsellType, metrics]);
        } else if (price.stock === undefined || price.stock > 0) {
            this.setState({ btnBusy: true });
            // pass price to metricParams so it can be tracked in cases where the price is not in the product object.
            let metricParams = {
                ...(metrics || {}),
                ...(product && !product.price && { price }),
                ...(conditionalMetrics || {}),
            };
            addToCart(
                product,
                quantity,
                true,
                addOns,
                { ...variant, userInputBaseSKU: serialNumber, removeExistingAssociation },
                baseProductSku,
                xsellType,
                metricParams,
            )
                .then(() => {
                    this.setState({ btnBusy: false });
                    launchCartFlyout({
                        analyticsData: {
                            gtmIdPrefix: 'cart-overlay',
                            ...(metrics || {}),
                        },
                    });
                })
                .catch(e => {
                    this.setState({ btnBusy: false });
                });
        }
    };

    openCustomModal = (event, openModal, data) => {
        const { customModalOnce } = this.state;
        const { once } = data;
        if (customModalOnce) {
            this.setState({ customModalOnce: !once });
            openModal();
        } else {
            this.purchase(event);
        }
    };

    customizeAndBuy() {
        const { quantity } = this.state;
        const { product, CTOQuery, jumpid, additionalParams = EMPTY_OBJECT, price, history } = this.props;
        let params = additionalParams;
        if (jumpid) {
            params.jumpid = jumpid;
        }
        if (CTOQuery) {
            const { color, configCatentryId } = CTOQuery;
            params = { ...params, color, configCatentryId };
        }
        history.push(Helpers.addUrlParam(price.ctoLink, params));
    }

    getCTAText = () => {
        const { price } = this.props;
        const { ctaText, customizeText } = price;

        return customizeText ? customizeText : ctaText;
    };

    getAriaLabel = () => {
        try {
            return `${this.getCTAText().replace(/ \& /gi, ' and ')} for ${this.props.product.name}`;
        } catch (error) {
            return '';
        }
    };

    setQuantity = quantity => {
        this.setState({ quantity });
    };

    getProductClass = () => {
        const { price, product, forceOOS, enableCTOAddToCart } = this.props;
        const { attributes = EMPTY_OBJECT, isComingSoon, pdpConfig = EMPTY_OBJECT } = product;
        const prdClass = product.product_type || product.prdClass;
        const { suppliesLinkUrl, ctoAddToCart } = attributes;
        const { pdpDetails = EMPTY_OBJECT } = pdpConfig;
        let prdDerivedClass;
        if (forceOOS) {
            prdDerivedClass = 'OOS';
        } else if (!suppliesLinkUrl && prdClass !== 'PRT' && Helpers.isProductOffline(price)) {
            prdDerivedClass = 'offline-product';
        } else if (product.eol) {
            if (suppliesLinkUrl && pdpDetails.replacementProduct) {
                prdDerivedClass = 'shop-ink';
            } else if (suppliesLinkUrl) {
                prdDerivedClass = 'view-supplies';
            } else {
                prdDerivedClass = 'OOS';
            }
        } else if (price.stock === 0 && !(isComingSoon || price.isComingSoonSku)) {
            prdDerivedClass = 'OOS';
        } else if (prdClass === '3PP') {
            prdDerivedClass = 'THREEPP';
        } else {
            prdDerivedClass = prdClass;
        }

        //add custom class for cto add to cart
        if (enableCTOAddToCart) {
            prdDerivedClass += ' cto-a2c';
        }

        return prdDerivedClass;
    };

    render() {
        const {
            showSelector,
            showSelectorHeader = false,
            product,
            price,
            productPrices,
            onClick,
            jumpid,
            customModalBlocked,
            variant,
            quantity,
            addToCart,
            enableCTOAddToCart,
            templateKey,
            enableAddToQuote,
            addOns,
            isccf,
            isV2,
            color,
            id,
            size,
            disabled: disableButton,
            metrics,
            variation = 'primary',
            loaderEnabled,
        } = this.props;
        const { forceOOS, priceFetchFailed, salePrice, regularPrice } = price;
        const { metadata, attributes = EMPTY_OBJECT, pdpConfig = EMPTY_OBJECT } = product;
        const prdClass = product.product_type || product.prdClass;
        const { suppliesLinkUrl } = attributes;
        const { ctaText } = metadata || EMPTY_OBJECT;
        const isComingSoon = (ctaText && ctaText.toLowerCase() === 'coming soon') || product.isComingSoon;
        const isGiftCard = Helpers.isGiftCard(product);
        const replacementLink = (pdpConfig.pdpDetails || EMPTY_OBJECT).replacementProduct;
        const quantityPicker =
            showSelector && prdClass !== 'CTO' ? (
                <div className="quantity-picker-container">
                    {showSelectorHeader && <span className="quantity-picker-header">Quantity: </span>}
                    <QuantityPicker quantity={quantity} onPick={this.setQuantity} enabled={price.stock !== 0} />
                </div>
            ) : null;
        const disabled =
            disableButton ||
            forceOOS ||
            (price.stock === 0 && !(suppliesLinkUrl || isComingSoon || price.isComingSoonSku));
        const prdDerivedClass = this.getProductClass();
        const isOOS = (price.stock === 0 && prdClass !== 'CTO') || forceOOS;
        const displayAddToQuote = enableAddToQuote && isccf && isOOS && !product.isComingSoon && prdClass !== 'CTO';

        if (prdClass === 'CTO' && product.eol) {
            return null;
        }
        if (price.hideAddToCart || priceFetchFailed) {
            return null;
        }
        if (!salePrice && !regularPrice) {
            return loaderEnabled ? <PurchaseButtonSkeleton /> : null;
        }

        const ctoGtmProps = {
            'data-gtm-category': 'linkClick',
            'data-gtm-id': 'product',
            'data-gtm-value': 'cto',
        };

        if (isGiftCard && !variant) {
            //if it's a gift card and no variant information is provided link to pdp
            return (
                <div className={`purchase-btn-container${prdDerivedClass ? ` ${prdDerivedClass}` : ''}`}>
                    <Link to={product.linkUrl} {...ctoGtmProps} className="btn btn-primary purchase-btn">
                        {'Customize & buy'}
                    </Link>
                </div>
            );
        }

        const { isMainSKU } = metrics || {};
        const extraGtmProps = isMainSKU ? { 'data-gtm-object': 'mainSKU' } : {};

        let cta_function;
        const btnText = this.getCTAText();
        let isCto = typeof prdClass === 'string' && /cto/i.test(prdClass);
        const ctaGtmProps =
            !disabled && metrics
                ? typeof prdClass === 'string' && /cto/i.test(prdClass)
                    ? addGtmCustomizeAndBuy(product, metrics, price, { forInlineAttribute: true })
                    : addGtmAddToCart(product, metrics, productPrices || price, metrics && metrics.cartId)
                : {};

        let ctoAddToCartGtmProps =
            enableCTOAddToCart &&
            isCto &&
            addGtmAddToCart(
                product,
                { ...metrics, enableCtoAddToCart: true, forInlineAttribute: true },
                price,
                metrics && metrics.cartId,
            );
        ctoAddToCartGtmProps = {
            ...(ctoAddToCartGtmProps || {}),
            ...extraGtmProps,
        };

        return (
            <PurchaseModal
                jumpid={jumpid}
                product={product}
                price={price}
                quantity={quantity}
                addToCart={addToCart}
                customModalBlocked={customModalBlocked}
                fetchXSellProducts={this.props.fetchXSellProducts}
                launchCartFlyout={this.props.launchCartFlyout}
                isV2={isV2}
                templateKey={templateKey}
            >
                {props => {
                    if (props.customModalData) {
                        cta_function = this.openCustomModal;
                    } else {
                        cta_function = this.purchase;
                    }

                    return (
                        <div className={`purchase-btn-container${prdDerivedClass ? ` ${prdDerivedClass}` : ''}`}>
                            {quantityPicker}
                            <div className="buy-button-container">
                                {replacementLink && (
                                    <div className="view-replacement btn">
                                        <Link to={replacementLink}>View Replacement Printer</Link>
                                    </div>
                                )}
                                {/* in the new version, add to quote button replaces regular oos button. In the old version, add to quote appears next to oos in pdps, not enabled in product cards*/}
                                {enableCTOAddToCart && (
                                    <CTOAddToCartComponent
                                        product={product}
                                        mobileOnly={false}
                                        isV2={isV2}
                                        gtmProps={ctoAddToCartGtmProps}
                                    />
                                )}
                                {!displayAddToQuote && (
                                    <Button
                                        variation={variation}
                                        disabled={disabled}
                                        color={color}
                                        id={id}
                                        aria-label={this.getAriaLabel()}
                                        onClick={e => {
                                            if (typeof onClick === 'function') {
                                                onClick(() => {
                                                    cta_function(
                                                        e,
                                                        props.openModal,
                                                        props.customModalData,
                                                        props.onPurchase,
                                                    );
                                                });
                                            } else {
                                                cta_function(
                                                    e,
                                                    props.openModal,
                                                    props.customModalData,
                                                    props.onPurchase,
                                                );
                                            }
                                        }}
                                        {...(prdClass === 'CTO' && !product.eol ? ctoGtmProps : {})}
                                        endIcon={
                                            prdClass === 'CTO' || btnText === 'View supplies' ? (
                                                <ArrowRight size="s" />
                                            ) : isOOS && price.isComingSoonSku ? null : (
                                                <Plus size="s" />
                                            )
                                        }
                                        className={'purchase'}
                                        success={this.state.btnBusy}
                                        size={size}
                                        {...ctaGtmProps}
                                        {...extraGtmProps}
                                    >
                                        {btnText}
                                    </Button>
                                )}
                                {displayAddToQuote && (
                                    <AddToQuote
                                        product={product}
                                        quantity={this.state.quantity}
                                        isOOS={isOOS}
                                        addOns={addOns}
                                        variant={variant}
                                        isV2={isV2}
                                    />
                                )}
                            </div>
                        </div>
                    );
                }}
            </PurchaseModal>
        );
    }
}

export { PurchaseButton as _UnconnectedPurchaseButton };
export default withPurchaseButton(withError(withRouter(PurchaseButton)));
