import React from 'react';
import { Button } from '@hpstellar/core';

import withPageComponents from '../../../../page/components/with-page-components';
import SimilarProductsLink from '../similar-products-link';
import './end-of-life-cta.less';

export const EoLLink = ({ productInitial, variation = 'secondary' }) => {
    if (!productInitial || !productInitial.eol) {
        return null;
    }

    const { ctaEol = 'VIEW ALTERNATE PRODUCT', ctaEolUrl } = productInitial;
    if (!ctaEolUrl) {
        return null;
    }
    return (
        <Button variation={variation} to={ctaEolUrl} className="eol-cta">
            {ctaEol}
        </Button>
    );
};

const EndOfLifeCTA = ({ productInitial }) => {
    if (!productInitial || !productInitial.eol) {
        return null;
    }
    const { ctaReplacementURL, ctaAddToCart, ctaEolUrl } = productInitial;
    const noReplacement = !ctaReplacementURL;
    const replaceMentCTA = noReplacement ? 'NOT AVAILABLE' : ctaAddToCart;
    return (
        <>
            <div className={`end-of-life-cta${ctaEolUrl || ctaReplacementURL ? ' flex' : ''}`}>
                <EoLLink productInitial={productInitial} />
                {(ctaEolUrl || ctaReplacementURL) && (
                    <Button disabled={noReplacement} to={ctaReplacementURL} className="eol-cta">
                        {replaceMentCTA}
                    </Button>
                )}
                {!ctaEolUrl && !ctaReplacementURL && (
                    <Button disabled={true} className="eol-cta">
                        {ctaAddToCart}
                    </Button>
                )}
            </div>
            <div className="eol-similar-products">
                <SimilarProductsLink />
            </div>
        </>
    );
};

export default withPageComponents(EndOfLifeCTA, { components: ['productInitial'] });
