import React, { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Helpers } from '../../core/src/helpers';
import { formatMetricValue } from '../../metrics/metrics-helpers';
import useTrackCustomMetric from '../../hooks/useTrackCustomMetric';
import { useBVSettings } from '../../hooks/useSiteConfig';

import { fetchBV } from '../bv-actions';
import BVTabs from './bv-tabs';

const BvReviews = props => {
    const {
        product,
        sections = ['questions', 'review_highlights', 'reviews'],
        tabs,
        tabState,
        pdpSEOReviews,
        allReviewsLink,
    } = props;
    const { sku, name } = product || {};
    const { transformedSKU } = useBVSettings(sku);

    const trackCustomMetric = useTrackCustomMetric();

    const scriptLoaded = useSelector(state => state && state.scriptLoader && state.scriptLoader['bv']);

    const trackMetrics = useCallback(
        event => {
            try {
                let { target } = event;
                // no easy way to do this with the HTML generated from BV
                if (/^see all reviews/i.test(target.innerHTML)) {
                    return trackCustomMetric('readReview', {
                        event: 'e_readReview',
                        productName: name,
                    });
                }

                const hasClass = target.className;
                if (hasClass && target.classList.contains('bv-write-review')) {
                    return trackCustomMetric('linkClick', {
                        event: 'e_linkClick',
                        linkPlacement: 'review',
                        linkId: 'write-review',
                    });
                }

                if (hasClass && target.classList.contains('bv-ask-question')) {
                    return trackCustomMetric('linkClick', {
                        event: 'e_linkClick',
                        linkPlacement: 'review',
                        linkId: 'ask-question',
                    });
                }

                if (hasClass && target.textContent === 'Post Question' && target.classList.contains('bv-submit')) {
                    const hasAcceptedTermsAndConditions = document.querySelector(
                        '.bv-submission-section #bv-checkbox-questions-termsAndConditions',
                    ).checked;
                    const hasValidQuestion =
                        document.querySelector('.bv-submission-section #bv-textarea-field-questionsummary').value
                            .length > 0;
                    const hasValidName =
                        document.querySelector('.bv-submission-section #bv-text-field-usernickname').value.length > 3;
                    const hasValidEmail = document
                        .querySelector(
                            '.bv-submission-section #bv-email-field-hostedauthentication_authenticationemail',
                        )
                        .checkValidity();
                    hasAcceptedTermsAndConditions &&
                        hasValidQuestion &&
                        hasValidName &&
                        hasValidEmail &&
                        trackCustomMetric('linkClick', {
                            event: 'e_linkClick',
                            linkPlacement: 'review',
                            linkId: 'post-question',
                        });
                }
            } catch (e) {}
        },
        [name],
    );

    useEffect(() => {
        if (scriptLoaded && transformedSKU) {
            fetchBV(transformedSKU);
        }
    }, [scriptLoaded]);

    useEffect(() => {
        const sortClickHandler = event => {
            const { target } = event || {};
            if (
                target.getAttribute('data-bv-dropdown-value') &&
                target.closest('.bv-absolute-top-container .bv-content-sort-dropdown .bv-dropdown')
            ) {
                trackCustomMetric('linkClick', {
                    event: 'e_linkClick',
                    linkPlacement: 'review',
                    linkId: `sort-by-${formatMetricValue(target.innerText)}`,
                });
            }
        };
        document.body.addEventListener('click', sortClickHandler);
        return () => {
            document.body.removeEventListener('click', sortClickHandler);
        };
    }, []);

    useEffect(() => {
        const postReviewHandler = event => {
            const { target } = event || {};
            if (!target.className || target.textContent !== 'Post Review' || !target.classList.contains('bv-submit')) {
                return;
            }
            try {
                const hasRatingSelection = document.querySelector(
                    '.bv-rating-field .bv-fieldset-rating-wrapper span[role=radio][aria-checked=true]',
                );
                const hasValidTitle = !document.querySelector(
                    '.bv-mbox-content-container #bv-text-field-title[aria-invalid=true]',
                );
                const hasValidReview = !document.querySelector(
                    '.bv-mbox-content-container .bv-review-field-content-wrapper #bv-textarea-field-reviewtext[aria-invalid=true]',
                );
                const hasValidName = !document.querySelector(
                    '.bv-mbox-content-container #bv-text-field-usernickname[aria-invalid=true]',
                );
                const hasValidEmail = !document.querySelector(
                    '.bv-mbox-content-container #bv-email-field-hostedauthentication_authenticationemail[aria-invalid=true]',
                );
                hasRatingSelection &&
                    hasValidTitle &&
                    hasValidReview &&
                    hasValidName &&
                    hasValidEmail &&
                    trackCustomMetric('productReviewed', {
                        event: 'e_productReviewed',
                        productName: name,
                    });
            } catch (e) {}
        };
        document.body.addEventListener('click', postReviewHandler);
        return () => {
            document.body.removeEventListener('click', postReviewHandler);
        };
    }, []);

    if (pdpSEOReviews) {
        return (
            <>
                <div dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(pdpSEOReviews.summary))} />
                <div dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(pdpSEOReviews.reviews))} />
                <div dangerouslySetInnerHTML={Helpers.createMarkup(Helpers.decodeHtml(pdpSEOReviews.content))} />
            </>
        );
    }

    if (tabs && tabs.length > 0) {
        return (
            <BVTabs tabs={tabs} tabState={tabState} transformedSKU={transformedSKU} allReviewsLink={allReviewsLink} />
        );
    }

    return (
        <div itemScope itemType="https://schema.org/Product" className="bv-reviews" onClick={trackMetrics}>
            {sections.map(sectionKey => (
                <div key={sectionKey} data-bv-show={sectionKey} data-bv-product-id={transformedSKU}></div>
            ))}
        </div>
    );
};

BvReviews.propTypes = {
    /* Product object, must contain the sku */
    product: PropTypes.object,
    /**
     * @param ("questions"|"review_highlights"|"reviews") sections
     */
    sections: PropTypes.arrayOf(PropTypes.oneOf(['questions', 'review_highlights', 'reviews'])),
    tabs: PropTypes.arrayOf(PropTypes.object),
};

export default BvReviews;
