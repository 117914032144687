import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import { setAddOns } from './addon-actions';
import { setCTOConfiguration } from './cto-actions';
import { getCTOLink } from '../../../core/src/etr-api-client/cto';

const ConfigurationWrapper = Component => props => {
    return <Component {...props} />;
};
const EMPTY_OBJECT = {};

//provides a default CTO config if applicable
const getDefaultState = productData => {
    try {
        const { colorCTOs } = productData || EMPTY_OBJECT;
        const defaultColor = (colorCTOs || []).find(c => c.default === 'yes');
        if (defaultColor) {
            return {
                color: defaultColor.name,
                error: undefined,
                isNotDefaultColor: false,
            };
        }
    } catch (e) {}
    return EMPTY_OBJECT;
};

function mapStateToProps(state, ownProps) {
    let { addOns, ctoConfigurations } = state.productData;
    const { product, productInitial } = ownProps;
    const productData = product || productInitial;
    /**
     * If product prop provided, automatically map to that product
     */
    if (productData && productData.sku) {
        const ctoConfig = ctoConfigurations[productData.sku] || getDefaultState(productData);
        const id = productData.itemId || productData.catEntryId || productData.catentryId;
        return {
            addOns: addOns[productData.sku] || EMPTY_OBJECT,
            //TODO: don't overload ctoConfigurations, it's confusing
            ctoConfigurations: ctoConfig,
            ctoConfig,
            ctoLink: getCTOLink(id, 1, ctoConfig),
        };
    }
    return {
        addOns,
        ctoConfigurations,
        ctoConfig: {},
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setAddOns: (sku, addOns) => dispatch(setAddOns(sku, addOns)),
        setCTOConfiguration: (sku, config) => dispatch(setCTOConfiguration(sku, config)),
    };
};

const withConfigurations = compose(connect(mapStateToProps, mapDispatchToProps), ConfigurationWrapper);

export default withConfigurations;
