import React from 'react';
import ProductCTA from '../../../product/components/purchase-button-v2';

import withPageComponents from '../../../page/components/with-page-components';
import withConfigurations from '../../../product/components/product-configurations';
import { getAddon } from '../../../metrics/metrics-helpers';
import useProductPrice from '../../../hooks/useProductPrice';
import useCartState from '../../../hooks/useCartState';
import { Helpers } from '../../../core/src/helpers';
import EnrollmentKit from './enrollment-kit';
import EndOfLifeCTA from './end-of-life-cta';

/**
 * Checks URL for serial number if current sku is an eCarepack
 * @param {*} productInitial
 * @param {*} location
 * @returns
 */
const getSerialNumber = (productInitial, location) => {
    try {
        if (Helpers.isECarepack(productInitial)) {
            const { query } = location || {};
            const { SerialNumber } = query || {};
            return SerialNumber;
        }
    } catch (e) {}
    return undefined;
};
const EMPTY_OBJ = {};
const CTAComponent = ({
    productInitial,
    ctoConfig,
    pdpCarePackBundle,
    addOns,
    location,
    disableCTOAddToCart,
    templateKey = 'pdp',
    loaderEnabled,
    btnProps,
}) => {
    if (!productInitial) return null;

    const { carepacks, serviceBundle } = pdpCarePackBundle || EMPTY_OBJ;
    const { eol, sku, product_type: prdClass } = productInitial;

    const { prices } = useProductPrice([
        productInitial,
        ...(Array.isArray(carepacks) ? carepacks : []),
        ...(serviceBundle && Array.isArray(serviceBundle.carepacks) ? serviceBundle.carepacks : []),
    ]);
    const { cartId } = useCartState();

    const {
        error,
        giftCardForm,
        configCatentryId,
        price: giftCardPrice = {},
        isNotDefaultColor,
        isNotDefaultConfig,
    } = ctoConfig || EMPTY_OBJ;

    let variant = {};
    if (giftCardForm && !error) {
        variant = { ...giftCardForm, itemId: configCatentryId, price: giftCardPrice.salePrice };
    }
    let selectedAddon = addOns && getAddon(sku, addOns);
    const productPrice = prices[sku];
    const { forceOOS } = productPrice || EMPTY_OBJ;

    return (
        !eol && (
            <ProductCTA
                id="pdpAddToCartBtn"
                product={productInitial}
                enableAddToQuote={true}
                enableCTOAddToCart={!disableCTOAddToCart && !forceOOS && !isNotDefaultColor && !isNotDefaultConfig}
                templateKey={templateKey}
                CTOQuery={ctoConfig}
                disabled={error}
                variant={variant}
                removeExistingAssociation={true}
                loaderEnabled={loaderEnabled}
                metrics={{
                    list: templateKey,
                    forInlineAttribute: true,
                    cartId,
                    baseProductSku: sku,
                    addons: carepacks || (serviceBundle && serviceBundle.carepacks),
                    addonMetrics: {
                        xsellMethod: 'pdp-carepack',
                        xsellProdInfo: sku,
                    },
                    selectedAddonSku: selectedAddon && selectedAddon.sku,
                    isMainSKU: true,
                }}
                getConditionalMetrics={() => {
                    try {
                        let list = document.querySelector('#pdpAddToCartBtn').getAttribute('data-add-to-cart-list');
                        return list ? { list } : {};
                    } catch (e) {}
                    return {};
                }}
                productPrices={prices}
                serialNumber={getSerialNumber(productInitial, location)}
                {...(btnProps || {})}
            />
        )
    );
};

export const CTA = withPageComponents(withConfigurations(CTAComponent), {
    components: ['productInitial', 'pdpCarePackBundle'],
    addons: true,
    location: true,
});

export default withPageComponents(
    ({
        device,
        pdpShadowCTO,
        productInitial,
        hideForShadowCTO,
        ctaBtnProps,
        hideEnrollmentKit,
        secondaryCTA,
        className = 'pdp-cta-row',
        loaderEnabled,
    }) => {
        const { enrollmentKit } = productInitial || {};
        const { shadowCTOEnabled } = pdpShadowCTO || {};
        //TODO: should instead just return null to hide. Right now sticky NAV relies on the CTA button to be on the page as a hack to trigger the xsell popup and anlytics
        let style = {};
        if (device !== 'mobile' && hideForShadowCTO && shadowCTOEnabled) {
            style = { display: 'none' };
        }
        const productCTA = (
            <>
                <CTA btnProps={ctaBtnProps} loaderEnabled={loaderEnabled} />
                <EndOfLifeCTA />
            </>
        );
        const displayEnrollmentKit = !hideEnrollmentKit && enrollmentKit && !secondaryCTA;
        const content = displayEnrollmentKit ? <EnrollmentKit productCTA={productCTA} /> : productCTA;
        const containerClassName = displayEnrollmentKit ? undefined : className;
        return (
            <div className={containerClassName} style={style}>
                {content}
                {secondaryCTA}
            </div>
        );
    },
    { components: ['pdpShadowCTO', 'productInitial'], device: true },
);
